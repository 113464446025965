import css from "./scss/style.scss";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
    faTwitter,
    faInstagram,
    faFacebookSquare,
    faFacebook,
    faFacebookF,
    faWhatsappSquare,
    faYoutube,
    faWhatsapp,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "./js/external-link";
import { initSticky } from "./js/menu-sticky";
import { initBurger } from "./js/burger";
import { initGallery } from "./js/gallery";
import { initSlide } from "./js/slider";
import { initNews } from "./js/slider-news";
import { initCopyClipBoard } from "./js/copy-clipboard";
import { initCollapsible } from "./js/collapsible";
import { nextSiblingRight } from "./js/right-text";
import { initModal } from "./js/modal-form";
import { initVideos, resizeVideos } from "./js/video";
import initDropDown from "./js/menu-dropdown";
import initMenuLocker from "./js/menu-locker";

library.add(
    fas,
    faTwitter,
    faFacebook,
    faFacebookF,
    faFacebookSquare,
    faWhatsappSquare,
    faInstagram,
    faYoutube,
    faWhatsapp,
    faLinkedin
);
dom.watch();

function initDelete() {
    (document.querySelectorAll("#notification-area .delete") || []).forEach(
        ($delete) => {
            const $notification = $delete.parentNode.parentNode;
            if (sessionStorage.getItem("notification") == "closed") {
                $notification.parentNode.removeChild($notification);
                document.body.classList.add("has-hidden-notification-area")
            } else {
                $notification.classList.remove("is-hidden");
                $delete.addEventListener("click", () => {
                    $notification.parentNode.removeChild($notification);
                    sessionStorage.setItem("notification", "closed");
                    document.body.classList.add("has-hidden-notification-area")
                });
            }
        }
    );
}

function initSite() {
    initSlide(".slider");
    initGallery(".gallery");
    initNews();
    initBurger(".app-navbar-toggle");
    initMenuLocker();
    initSticky();
    initCopyClipBoard();
    initCollapsible();
    initModal();
    initDropDown();
    initDelete();
    nextSiblingRight();
    initVideos(".block-video iframe");
    initVideos(".block-video_list iframe");
    resizeVideos(".block-video iframe");
    resizeVideos(".block-video_list iframe");
}

document.addEventListener("DOMContentLoaded", initSite, false);
