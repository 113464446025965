
const getAncestors = (start, terminal) => {
  const ancestorIterator = function* (e) {
    while ((e = e.parentNode)) {
      if (e == terminal) {
        break;
      }
      yield e;
    }
  };
  return [...ancestorIterator(start), terminal];
};

const handleMenu = (event) => {
  const current = event.target;
  const item = current.closest('.app-menu-item');

  const terminal = current.closest(".app-level_1");
  const ancestors = getAncestors(current, terminal);
  const active = document.querySelectorAll(".app-menu-item");

  active.forEach((e) => {
    if (e != item && !ancestors.includes(e)) {
      e.firstElementChild.classList.remove('app-rotate-arrow');
      e.lastElementChild.classList.remove("app-show-menu");
    }
  });
  item.firstElementChild.classList.toggle('app-rotate-arrow')
  item.lastElementChild.classList.toggle('app-show-menu');
};

const closeMenu = (event) => {
  const current = event.target;
  const item = current.closest('.app-dropdown');
  item.classList.remove('app-show-menu');
};

const initDropDown = (selector = ".app-dropdown-menu") => {
  const mainMenu = document.querySelectorAll(selector);

  mainMenu.forEach((el) => {
    el.querySelectorAll(".app-trigger").forEach((e) => {
      e.addEventListener("click", handleMenu);
    });
    el.querySelectorAll(".app-close-menu").forEach((e) => {
      e.addEventListener("click", closeMenu);
    });
  })
};

export default initDropDown;

/*

  Estructura requerida:
  - app-menu-item tiene sólo dos hijos

  <div class="app-dropdown-menu">
    [...]
    <div class="app-menu-item">
      <div class="app-trigger">
      [...]
      </div>
      <div class="app-dropdown app-show-menu"> // app-show-menu responde a un toggle
      [...]
      </div>
    </div>
  </div>

*/