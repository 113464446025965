export function initSticky() {
  const stickyMenu = document.getElementById("navbar");
  const stickyOffset = stickyMenu.offsetTop;
  let notificationArea = document.getElementById("notification-area");
  const body = document.body;

  if (notificationArea !== null) {
    body.classList.add("has-notification-area");
  }

  window.addEventListener("scroll", function (e) {
    requestAnimationFrame(() => {
      if (window.pageYOffset > stickyOffset) {
        stickyMenu.classList.add("app-scroll-style");
        body.classList.remove("has-notification-area")
      } else {
        stickyMenu.classList.remove("app-scroll-style");
        if (notificationArea !== null) {
          body.classList.add("has-notification-area");
        }
      }
    });
  });
}