import Glide from "@glidejs/glide";
import { initModal } from "./modal";

class SlidesEventHandler {
  constructor (slides, events) {
    this.slides = slides;
    this.events = events;
  }
  addClass (active) {
    if (active) {
      active.classList.add("inactive");
    }
  }
  removeClass (active) {
    if (active) {
      active.classList.remove("inactive");
    }
  }
  mount() {
    this.events.on("move", (e) => {
      const active = this.slides[0];
      this.addClass(active);
    });
    this.events.on("move.after", (e) => {
      const active = this.slides[0];
      this.removeClass(active);
    });
  }
}

const Animation = (Events) => {
  const slide = document.querySelector(Events.selector);
  const activeSlides = slide.getElementsByClassName("glide__slide--active");
  return new SlidesEventHandler(activeSlides, Events);

};

const glideOptions = {
  type: "carousel",
  perView: 1,
  gap: 0,
  autoplay: 3000,
  animationDuration: 400,
  animationTimingFunc: "ease-in",
  breakpoints: {
    480: {
      perView: 1,
    },
  },
};

function getAll(selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
}

const buildModal = (
  url,
  alt,
  full,
  target,
  text,
  author
) => `<div id="${target}" class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <figure>
      <p class="image">
        <img src="${url}" alt="${alt}">
      </p>
      <figcaption>
        <em id="img-text">${text}</em>
        <small id="img-author">${author}</small>
      </figcaption>
    </figure>
  </div>
  <div class="glide__arrows" data-glide-el="controls">
    <button class="glide__arrow glide__arrow--left" data-glide-dir="<"><span class="icon is-medium"><i class="fas fa-2x fa-chevron-left"></i></span></button>
    <button class="glide__arrow glide__arrow--right" data-glide-dir=">"><span class="icon is-medium"><i class="fas fa-2x fa-chevron-right"></i></span></button>
  </div>
  <button class="modal-close is-large" aria-label="close"></button>
</div>`;

const doHash = () => Math.random().toString(36).substring(7);

function press(event) {
  let direction = "";
  if (event.keyCode === 39 || event.keyCode === 9) {
    event.preventDefault();
    direction = ">"
  } else if (event.keyCode === 37) {
    event.preventDefault();
    direction = "<"
  }
  return direction;
}

function updateModal(glide, direction) {
  if (direction !== "") {
    const galleryId = glide.selector;
    const gallery = document.querySelector(galleryId);
    const sliceActive = gallery.querySelector(".glide__slide--active");
    const imageModal = document.querySelector(".modal-content .image img");
    const imageFileSrc = document.querySelector(".modal a");

    const nextSlice =
      direction === ">"
        ? sliceActive.nextElementSibling
        : sliceActive.previousElementSibling;

    const value = nextSlice.querySelector(".image a").getAttribute("href");
    if (imageModal) {
      imageModal.setAttribute("src", `${value}`);
      imageFileSrc.setAttribute("href", `${value}`);
      imageFileSrc.setAttribute("download", `${value}`);

      // Figcaption
      const figcaptionData = nextSlice.querySelector(".image a").dataset;

      document.getElementById("img-text").textContent=`${figcaptionData.text}`
      document.getElementById("img-author").textContent=`${figcaptionData.author}`
    }

    glide.go(direction);
  }

}

const slideHandler = (event, glide) => {
  event.stopPropagation();
  event.preventDefault();
  const target = `modal-${doHash()}`;
  const rootEl = document.documentElement;
  const element = event.currentTarget;
  const alt = element.querySelector("img").getAttribute("title");
  const url = element.getAttribute("href");
  const full = element.dataset.originalImage;
  const text = element.dataset.text;
  const author = element.dataset.author;

  element.dataset.target = target;

  const wrapper = document.getElementById("wrapper");
  wrapper.insertAdjacentHTML("beforeend", buildModal(url, alt, full, target, text, author));

  const next = document.querySelectorAll(".glide__arrow");
  next.forEach((c) =>
    {c.addEventListener("click", () => {updateModal(glide, c.dataset.glideDir);})
    }
  );

  wrapper.classList.add("is-clipped");
  wrapper.addEventListener("keydown", (event) => {
    updateModal(glide, press(event));
  })
  const modal = document.getElementById(target);

  const modalCloses = getAll(
    ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button"
  );

  if (modalCloses.length > 0) {
    modalCloses.forEach(function (el) {
      el.addEventListener("click", function () {
        closeModals(rootEl, modal);
      });
    });
  }

  const closeModals = (rootEl, modal) => {
    rootEl.classList.remove("is-clipped");
    wrapper.classList.remove("is-clipped");
    modal.classList.remove("is-active");
    modal.remove();
  };
};

export const initGallery = (selector = ".gallery") => {
  const galleries = document.querySelectorAll(selector);
  if (galleries.length) {
    galleries.forEach((slide) => {
      let hash = doHash();
      let id = `gallery-${hash}`;
      slide.setAttribute("id", id);
      glideOptions.perView = slide.dataset.perview;


      let glide = new Glide(`#${id}`, glideOptions);

      glide.mount({Animation});

      let childs = slide.querySelectorAll("figure.image > a");
      childs.forEach(
        (c) =>
        c.addEventListener("click", function (e) {
          slideHandler(e, glide);
        })
      );
    });
    initModal();
  }
};