const API_ENDPOINT = "/search/promotions";
const SEARCH_URL = "/search/";

function renderPromotions(data, element) {
  for (let { query, page, sortOrder } of data.results) {
    let promotion = `<li><a href="${SEARCH_URL}?query=${query}">${page}</a></li>`;
    let fragment = document.createRange().createContextualFragment(promotion);
    element.appendChild(fragment);
  }
}

function initSearchPromotions(target) {
  const element = document.getElementById(target);
  fetch(API_ENDPOINT).then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        if (data.count != 0) {
          const title= document.getElementById('title-promotions');
          title.classList.remove("is-hidden")
        }
        renderPromotions(data, element)
      });
    }
  });
}

function batchToggle(selector, pivot, className) {
  const nodeList = document.querySelectorAll(selector);
  [...nodeList].forEach((e) => {
    if (e !== pivot) {
      e.classList.remove(className);
    }
  });
}

function initTarget(name, node) {
  if (name === "finder") {
    initSearchPromotions("search-promotions");

    node.getElementsByClassName("search-input")[0].focus();
  }
}

export function initBurger(selector) {
  // Get all "app-navbar-toggle" elements
  const navbarSearch = document.querySelectorAll(selector);

  navbarSearch.forEach((el) => {
    el.addEventListener("click", () => {
      
      const targetId = el.dataset.target;
      const target = document.getElementById(targetId);

      batchToggle(".app-navbar-toggle", el, "is-active");
      batchToggle(".app-nav-layout", target, "is-active");
      el.classList.toggle('is-active');
      target.classList.toggle('is-active');

      if ((!el.classList.contains("is-present")) && (el.classList.contains("is-active"))) {
        const targetId = el.dataset.target;

        if (targetId) {
          const target = document.getElementById(targetId);
          if (target) {
            initTarget(target.id, target);
          }
          el.classList.add("is-present");
        }
      }
    });

    const closeMenu = document.getElementById("app-menu-close");
    const searchMenu = document.getElementById("dropdown-menu");

    if (closeMenu) {
      closeMenu.addEventListener("click", () => {
        searchMenu.classList.remove("is-active");
      });
    }

    /* const items = el.querySelectorAll(".dropdown-menu .dropdown-item");
    items.forEach((item) => {
      item.addEventListener("click", () => {
        el.classList.remove("is-active");
      })
    }) */

  });
}
