export function initModal(blockSelector = ".block-form .button") {
    const block = document.querySelectorAll(blockSelector);
    const rootEl = document.documentElement;

    block.forEach((trigger) => {
      let target = trigger.parentElement.querySelector(".modal");
      let close = trigger.parentElement.querySelector(".modal-close");

      trigger.addEventListener("click", () => {
        target.classList.add("is-active");
      });

      close.addEventListener("click", () => {
        rootEl.classList.remove("is-clipped");
        target.classList.remove("is-active");
      });
    });
  }
