const showToolTip = (el) => {
  el.setAttribute("data-tooltip", "Vínculo copiado al portapapeles.");
  el.classList.add("tooltip", "is-tooltip-active");
  setTimeout(() => el.classList.remove("tooltip", "is-tooltip-active"), 2000);
};

const copyToClipboard = (event) => {
  event.preventDefault();
  const content = document.createElement("textarea");
  const target = event.currentTarget;
  content.value = target.href;
  // Set non-editable to avoid focus and move outside of view
  content.setAttribute("readonly", "");
  content.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(content);
  // Select text inside element
  content.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(content);
  showToolTip(target);
};

export const initCopyClipBoard = (selector = ".share-link") => {
  const copyelems = document.querySelectorAll(selector);

  copyelems.forEach((el) => {
    el.addEventListener("click", (event) => copyToClipboard(event));
  });
};
