export function initCollapsible(blockSelector = "accordion-header") {
    const blocks = document.querySelectorAll(".block-related_content, .block-suggested_link, .block-accordion, .block-video_list");

    let currentContainer = null;

    blocks.forEach(block => {
        const hasAccordions = block.querySelector(".accordions");
        if (!hasAccordions) {
            return; // Skip this block if it doesn't have .accordions
        }

        const type = getType(block);

        if (!currentContainer || !currentContainer.classList.contains(`accordions-container-${type}`)) {
            currentContainer = document.createElement("div");
            currentContainer.classList.add(`accordions-container-${type}`);
            block.parentNode.insertBefore(currentContainer, block);
        }

        currentContainer.appendChild(block);

        const elements = block.getElementsByClassName(blockSelector);
        for (const element of elements) {
            element.addEventListener("click", (event) => {
                event.preventDefault();
                element.parentNode.classList.toggle("active");
            });
        }
    });

    function getType(block) {
        if (block.classList.contains("block-related_content")) {
            return "related";
        } else if (block.classList.contains("block-suggested_link")) {
            return "suggested";
        } else if (block.classList.contains("block-accordion")) {
            return "accordion";
        } else if (block.classList.contains("block-video_list")) {
            return "video_list";
        }
        return ""; // Default type
    }
}