
export function nextSiblingRight() {
    const rightImg = document.querySelectorAll('.richtext-image.right');

    rightImg.forEach((elemento) => {
        const nextEl = elemento.nextElementSibling;
        nextEl.classList.add("text-left");

        if (nextEl) {
            elemento.parentNode.insertBefore(nextEl, elemento);
        }
    });
}
