import Glide from "@glidejs/glide";

export const initNews = (selector=".slider-news") => {
  const slides = document.querySelectorAll(selector);
  if (slides.length ) {
    const glideOptions = {
      type: "slider",
      perView: 4,
      autoplay: false,
      bound: true,
      focusAt: "0",
      gap: 0,
      breakpoints: {
        800: {
          perView: 3,
        },
        480: {
          perView: 1,
        },
      },
    };

    slides.forEach((element) => {
      let item = element;
      let d = new Date();
      let id = "slider-news-" + d.getTime();
      item.setAttribute("id", id);
      let slider = new Glide("#" + id, glideOptions);
      slider.mount();
    });
  }
};


