const initMenuLocker = (selector = ".app-office-mark") => {
    const officeMark = document.querySelector(selector);
    let currentOffice;
    let _ = require('lodash');

    if (officeMark.id !== "") {
            currentOffice = officeMark.id;
    }
    if (currentOffice !== undefined && currentOffice !== null) {
        const titleSelector = currentOffice + "-title"
        const title = document.getElementById(titleSelector)
        const submenuSelector = currentOffice + "-submenu"
        const submenu = document.getElementById(submenuSelector);
        openFixed();

        title.classList.add('current-office');

        function openFixed() {
            title.classList.add('fixed-title');
            if (submenu) {
                submenu.classList.add('fixed-submenu');
            }
        };

        function closeFixed() {
            title.classList.remove('fixed-title');
            if (submenu) {
                submenu.classList.remove('fixed-submenu');
            }
        };

        const allTitles = document.querySelectorAll(".app-dropdown-menu");

        allTitles.forEach((e) => {
            e.addEventListener("mouseover", closeFixed);
            e.addEventListener("mouseout", openFixed);
            });

        let lastScrollTop = 0;

        function requestAnimationFrame() {
            let hovered = document.querySelector(".office-title:hover");
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                // downscroll code
                closeFixed();
            } else if (hovered === null) {
                // upscroll code
                openFixed();
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }
        window.addEventListener("scroll", _.throttle(requestAnimationFrame, 150), false);
    };
}

export default initMenuLocker;