export const initVideos = (selector) => {

    const iframes = document.querySelectorAll(selector);

    // Find &amp;amp;#x26; save the aspect ratio for all videos
    iframes.forEach((e) => {
        const height = e.getAttribute("height");
        const width = e.getAttribute("width");
        e.setAttribute("data-ratio", height / width);

        // Remove the hardcoded width &amp;amp;#x26; height attributes
        e.setAttribute("width", "");
        e.setAttribute("height", "");
    });
};

export const resizeVideos = (selector) => {
    const iframes = document.querySelectorAll(selector);

    // Find all videos
    iframes.forEach((e) => {
        // Get the parent container&amp;amp;#x27;s width
        const parentWidth = e.parentElement.offsetWidth;
        const newHeight = Math.ceil(parentWidth * e.getAttribute("data-ratio"));
        e.setAttribute("width", parentWidth);
        e.setAttribute("height", newHeight);
        e.setAttribute(
            "style",
            `width: ${parentWidth}px; height: ${newHeight}px`
        );
    });
};
