export function initModal(blockSelector = ".block-form .button") {
  const block = document.querySelectorAll(blockSelector);
  const rootEl = document.documentElement;

  block.forEach((trigger) => {
    let target = document.querySelector(".modal");
    let close = document.querySelector(".modal-close");

    trigger.addEventListener("click", () => {
      target.classList.toggle("is-active");
    });

    close.addEventListener("click", () => {
      rootEl.classList.remove("is-clipped");
      target.classList.remove("is-active");
    });
  });
}
